import React, { Component } from 'react';
import styled from 'styled-components'
import Line from '../components/Background/Line'

const Main = styled.section`
    background-color: #0f0f0f;
    width: 100%;
    height: 100vh;
    overflow: hidden;
`;

class Background extends Component {
    constructor(props) {
        super(props);

        const numberOfLines = Math.ceil(window.screen.height / 20)

        const linesOption = []
        for (let i = 0; i < numberOfLines; i++) {
            linesOption.push({ ref: React.createRef(), timeout: Math.floor(Math.random() * 5) + 1, direction: Math.round(Math.random()) === 0 ? -1 : 1 })
        }

        this.state = {
            linesOption
        }

        this.animation()
    }

    animation = () => {
        this.state.linesOption.forEach(el => {
            if (el.ref.current) {
                el.ref.current.changePosition()
            }
        })

        // Don't use background animation - it's useless
        // setTimeout(this.animation, 2000)
    }

    renderLines = () => {
        return (
            this.state.linesOption.map((el, i) =>
                <Line key={i} ref={this.state.linesOption[i].ref} it={i} timeout={this.state.linesOption[i].timeout} direction={this.state.linesOption[i].direction} />
            )
        )
    }

    render() {
        return (
            <Main>
                {this.renderLines()}
            </Main>
        );
    }
}

export default Background;
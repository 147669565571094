import { Component } from 'react';
import styled from 'styled-components'

const Wrapper = styled.div`
    :first-child{
        margin-top: 25px !important;
    }

    :last-child{
        margin-bottom: 30px !important;
    }

    text-align: left;
    width: 80%;
    margin: 50px auto;
    max-width: 700px;
`

const Title = styled.a`
    display: block;
    width: fit-content;
    font-size: 28px;
    line-height: 40px;
    color: black;
    text-decoration: underline;
    text-decoration-thickness: 3px; 
    margin-bottom: 10px;

    :hover{
        cursor: pointer;
    }

    @media(max-width: 420px){
        font-size: 22px;
        line-height: 30px;
    }

`

const Description = styled.div`
    text-align: left;
    line-height: 20px;
`

const TechnologiesUsed = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
`

const Technology = styled.h4`
    padding: 0 10px;
    text-align: center;
    font-weight: 500;
`

const DateWrapper = styled.h5`
    margin-top: 1.33em;
    margin-bottom: calc(1.33em - 3px);
    line-height: 18px;
`

class ProjectElement extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Wrapper>
                <Title tabindex="-1" target="_blank" href={this.props.link}>{this.props.title}</Title>
                <DateWrapper>{this.props.date}</DateWrapper>
                <Description dangerouslySetInnerHTML={{ __html: this.props.description }}></Description>
                <h4 style={{ marginBottom: 5 }}>Technologies used:</h4>
                <TechnologiesUsed>
                    {this.props.technologies.map((name, i) => <Technology key={`technology-${this.props.k}-${i}`}>{name}</Technology>)}
                </TechnologiesUsed>
            </Wrapper>
        );
    }
}

export default ProjectElement;
import React, { Component } from 'react';
import styled from 'styled-components'

const LineWrapper = styled.div`
    color: whitesmoke;
    font-size: 20px;
    position: relative;
    top: 0;
`

const NumberElement = styled.div`
    color: #524f4f;
    display: inline;
    transition: 0.3s;
    ::selection{
        display: none;
    }
`

class Line extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            timeout: this.props.timeout,
            counter: 0,
            direction: this.props.direction,
            textValue: this.randomString(),
            childIndex:1000,
        }
    }

    changePosition = () => {
        let counter = this.state.counter
        
        if(counter>this.state.timeout){
            if(this.state.direction<0){
                let old = this.state.textValue
                old.shift()
                old.push(<NumberElement key={`${this.props.it}-${this.state.childIndex+1}`}>{Math.round(Math.random())}</NumberElement>)
                this.setState({
                    textValue: old,
                    childIndex: this.state.childIndex+2
                })
            }else{
                let old = this.state.textValue
                old.pop()
                old.unshift(<NumberElement key={`${this.props.it}-${this.state.childIndex+1}`}>{Math.round(Math.random())}</NumberElement>)
                this.setState({
                    textValue: old,
                    childIndex: this.state.childIndex+2
                })
            }   
            
            counter = -1
        }

        counter+=1

        this.setState({
            counter
        })
    }

    randomString = () => {
        let str = []
        let i = 0;
        for(;i<window.screen.width;i+=10){
            str.push(<NumberElement key={`${this.props.it}-${i}`}>{Math.round(Math.random())}</NumberElement>)
        }
        this.setState({
            childIndex: i
        })
        return str
    }

    render() { 
        return ( 
            <LineWrapper>
                {this.state.textValue}
            </LineWrapper>
         );
    }
}
 
export default Line;
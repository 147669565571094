import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { useSwipeable } from 'react-swipeable';
import axios from 'axios';

import githubImage from '../assets/images/github.png'
import linkedinImage from '../assets/images/linkedin.png'
// import codeforcesImage from '../assets/images/codeforces.png'

import ProjectElement from '../components/Main/ProjectElement';

const Wrapper = styled.div`
    width: 700px;
    height: auto;
    background-color: #696969;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%) rotateX(0deg);
    z-index: 2;
    color:black;
    padding: 20px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;       
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @media(max-width: 750px){
        width: 90vw;
        height: auto;
    }

    @media(max-width: 250px){
        width: 220px;
    }

    /* @media(max-height: 431px){
        top: 0;
        transform: translate(-50%,0%) rotateX(0deg);
    } */

`

const TransformWrapper = styled.div`
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: transform 1s;
    transform-style: preserve-3d;
    pointer-events: all;
/* 
    @media(max-height: 431px){
        top: 0;
    } */
`

const Section = styled.section`
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    transition: 1s;
    transform-style: preserve-3d;
    overflow: hidden;
    pointer-events: all;
    /* @media(max-height: 431px){
        overflow-y: scroll;
    } */
`

const BorderWrapper = styled.div`
    border: 3px solid black;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    position: relative;
    min-height: 385px;
`

const FlexElement = styled.div`
    margin: 10px 0;
    width: 100%;
`

const LinkElement = styled.a`
    display: block;
    width: 35px;
    height: 35px;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    transition: transform .3s;

    :hover{
        transform: translateY(-2.5px);
    }
`

const LinkWrapper = styled.div`
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: flex;
    width: 120px;
    justify-content: space-between;
    align-items: baseline;
`

const Paragraph = styled.h4`
    width: 80%;
    text-align: center;
    margin: 0 auto 40px auto;
    line-height: 20px;
`

const ParagraphMenu = styled.h4`
    display: block;
    width: 80%;
    text-align: center;
    margin: 0 5px 40px 5px;
    line-height: 20px;
    cursor: pointer;
    position: relative;
    width: fit-content;
    padding: 0 5px;
    transition: .2s;
    display: flex;
    justify-content: center;
    align-items: center;

    :after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: black;
        z-index: -1;
        transition: .4s;
    }

    @media(min-width: 480px){

    :focus{
        outline: none;
        color: white;
    }

    :focus:after{
        height: 120%;
    }

    :hover{
        color: white;
    }

    :hover:after{
        height: 120%;
    }

}


`

const ProjectsWrapper = styled.section`
    width: 100%;
    height: calc(100% - 10px);
    position: absolute;
    top: 10px;
    left: 0;
    background: #dcdcdc;
    border-top-left-radius: 75px;
    border-top-right-radius: 75px;
    z-index: 3;
    transition: .5s;
    text-align: center;
    overflow: hidden;
    user-select: text;
`

const CardHandWrapper = styled.div`
    width: 100%;
    height: 93px;
    position: fixed;
`

const CardHand = styled.div`
    width: 125px;
    height: 10px;
    border-radius: 20px;
    background-color: gray;
    margin: 10px auto;
    cursor: pointer;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
`

const StyledInput = styled.input`
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 2px solid black;
    font-family: inherit;
    margin-top: 10px;
    padding-bottom: 5px;
`

const StyledTextarea = styled.textarea`
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 2px solid black;
    font-family: inherit;
    min-height: 150px;
    margin-top: 10px;
`

const StyledForm = styled.form`
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 400px;
`

const FromStatus = styled.h4`
    width: 100%;
    height: 16px;
    margin-top: 15px;
`

const ProjectElementWrapper = styled.div`
    width: 100%;
    height: calc(100% - 91px);
    margin: 0 auto;
    overflow-y: scroll;

    @media (min-width: 480px) {
        &::-webkit-scrollbar {
        width: 12px;
        background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
        background-color: gray;
        }

        &::-webkit-scrollbar-track {
        background-color: transparent;
        }
    }
`

const ProjectTitle = styled.h1`
    margin-top: 40px;
    font-size: 36px;
    margin-bottom: 15px;
    user-select: none;

    @media(max-width: 420px){
        font-size: 28px;
        padding: 10px;
    }
`
const Main = () => {
    const [transform, setTransform] = useState("translate(-50%,-50%) rotateX(0deg)")
    const [projectsTop, setProjectsTop] = useState("100%")
    const [textarea, setTextarea] = useState("")
    const [email, setEmail] = useState("")
    const [formStatus, setFormStatus] = useState("")
    const [data, setData] = useState({})
    const [isVisible, setVisibility] = useState(0)

    useEffect(() => {
        fetch('website-configuration.json').then(response => {
            response.json().then(settings => {
                setData(settings);
                setVisibility(1)
            })
        })
    }, [])

    const handleClick = mode => {
        if (document.querySelector('.wrapper').classList.contains('wrapperTop')) {
            document.querySelector('.section').scrollTop = 0
        }
        if (mode === 'contact') {
            setTransform(transform === "translate(-50%,-50%) rotateX(0deg)" ? "translate(-50%,-50%) rotateX(180deg)" : "translate(-50%,-50%) rotateX(0deg)")
        } else if (mode === 'projects') {
            setProjectsTop(projectsTop === 10 ? '100%' : 10)
        }
        else if (mode === 'projects_swipe') {
            if (projectsTop === '100%') return;
            setProjectsTop(projectsTop === 10 ? '100%' : 10)
        }
    }

    const handleForm = async e => {
        try {
            e.preventDefault()
            setFormStatus("Please wait...")
            await axios.post('https://www.klatka.it/sendMail', { sender: email, message: textarea })
            setFormStatus("E-mail sent :)")
        } catch (error) {
            setFormStatus("Try again :(")
        }
    }

    const handleTextarea = e => {
        setTextarea(e.target.value)
    }

    const handleEmail = e => {
        setEmail(e.target.value)
    }

    const handlers = useSwipeable({
        onSwipedDown: () => handleClick('projects_swipe'),
        onTap: () => handleClick('projects_swipe'),
        trackMouse: true,
        preventDefaultTouchmoveEvent: true,
    });

    return (
        <>
            <Section style={{ opacity: isVisible }} className="section">
                <TransformWrapper className="transformWrapper" style={{ transform }}>
                    <Wrapper className="wrapper">
                        <BorderWrapper>
                            <FlexElement>
                                <h1 style={{ marginTop: 40, marginBottom: 10 }}>{data.header}</h1>
                                <Paragraph>{data.mainIntroduction}</Paragraph>
                                <Paragraph dangerouslySetInnerHTML={{ __html: data.status }}></Paragraph>
                            </FlexElement>
                            <FlexElement style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', width: '60%', flexDirection: 'row', marginTop: -20, marginBottom: 20 }}>
                                <ParagraphMenu onClick={() => handleClick('projects')}>Projects</ParagraphMenu>
                                <ParagraphMenu onClick={() => handleClick('contact')} >Contact</ParagraphMenu>
                            </FlexElement>
                            <LinkWrapper>
                                <LinkElement title="Github" target="_blank" href="https://github.com/pklatka" style={{ backgroundImage: `url(${githubImage})` }}></LinkElement>
                                <LinkElement title="LinkedIn" target="_blank" href="https://www.linkedin.com/in/patrykklatka" style={{ backgroundImage: `url(${linkedinImage})` }}></LinkElement>
                                {/* <LinkElement target="_blank" href="https://codeforces.com/profile/pklatka" style={{backgroundImage: `url(${codeforcesImage})`}}></LinkElement> */}
                                <LinkElement title="contact@klatka.it" href="mailto:contact@klatka.it" style={{ textDecoration: 'none', color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 40 }}>
                                    <div style={{ position: 'absolute', top: 0, height: 35, paddingLeft: 5 }}>@</div>
                                </LinkElement>
                            </LinkWrapper>
                        </BorderWrapper>
                    </Wrapper>
                    <Wrapper className="wrapper-2" style={{ transform: "translate(-50%,-50%) rotateX(180deg)" }}>
                        <BorderWrapper>
                            <FlexElement>
                                <h1 style={{ marginTop: 0, marginBottom: 10 }}>Contact</h1>
                                <StyledForm method="POST" onSubmit={handleForm}>
                                    <StyledInput tabIndex="1" required type="email" value={email} onChange={handleEmail} placeholder="What's your e-mail?" />
                                    <StyledTextarea tabIndex="2" required value={textarea} onChange={handleTextarea} placeholder="Write your message" />
                                    <FromStatus>{formStatus}</FromStatus>
                                    <input hidden id="submitForm" type="submit" value="Submit" />
                                </StyledForm>

                            </FlexElement>
                            <FlexElement style={{ display: 'flex', width: '60%', justifyContent: 'space-around', flexDirection: 'row', marginTop: -20 }}>
                                <ParagraphMenu onClick={() => handleClick('contact')} >Go back</ParagraphMenu>
                                <ParagraphMenu tabIndex="3" onKeyDown={e => {
                                    if (e.code === "Enter") {
                                        document.querySelector('input#submitForm').click()
                                    }
                                }} onClick={() => {
                                    document.querySelector('input#submitForm').click()
                                }} >Send</ParagraphMenu>
                            </FlexElement>
                        </BorderWrapper>
                    </Wrapper>
                </TransformWrapper>
            </Section>

            <ProjectsWrapper style={{ top: projectsTop }}>
                <CardHandWrapper {...handlers}>
                    <CardHand onClick={() => handleClick('projects_swipe')}></CardHand>
                </CardHandWrapper>
                <ProjectTitle>Projects</ProjectTitle>
                <ProjectElementWrapper>
                    {data.projects ? data.projects.map((project, i) => <ProjectElement k={i} key={`project-element-${i}`} title={project.title} date={project.date} link={project.link} description={project.description} technologies={project.technologies} />) : ""}
                </ProjectElementWrapper>
            </ProjectsWrapper>
        </>
    );
}

export default Main;
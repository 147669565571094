import React, { Component } from 'react';
import Background from './pages/Background'
import Main from './pages/Main'


class App extends Component {

  componentDidMount() {
    setTimeout(() => {
      document.querySelectorAll('a').forEach(e => e.setAttribute('tabindex', '-1'))
    }, 1300)

    const wrapper = document.querySelector('.wrapper')
    const wrapper2 = document.querySelector('.wrapper-2')
    const transformWrapper = document.querySelector('.transformWrapper')
    const section = document.querySelector('.section')

    const checkWrapper = () => {
      if (window.innerHeight <= wrapper.getBoundingClientRect().height) {
        // Snap wrapper to top
        if (!wrapper.classList.contains('wrapperTop')) {
          wrapper.classList.add('wrapperTop')
        }
        if (!transformWrapper.classList.contains('transformWrapperTop')) {
          transformWrapper.classList.add('transformWrapperTop')
        }
        if (!section.classList.contains('sectionTop')) {
          section.classList.add('sectionTop')
        }
        if (!wrapper2.classList.contains('wrapperTop2')) {
          wrapper2.classList.add('wrapperTop2')
        }
      } else {
        if (wrapper.classList.contains('wrapperTop')) {
          wrapper.classList.remove('wrapperTop')
        }
        if (transformWrapper.classList.contains('transformWrapperTop')) {
          transformWrapper.classList.remove('transformWrapperTop')
        }
        if (section.classList.contains('sectionTop')) {
          section.classList.remove('sectionTop')
        }
        if (wrapper2.classList.contains('wrapperTop2')) {
          wrapper2.classList.remove('wrapperTop2')
        }
      }
    }

    window.onresize = () => checkWrapper()

    checkWrapper()
  }

  render() {
    return (
      <>
        <Background />
        <Main />
      </>
    );
  }
}

export default App;